import { Link, redirect } from "react-router-dom";
import GlobalObject from './globalObject';

// const Navbar = () => {
//   return (
//     <nav>
//       <ul>
//         <li>
//           <Link to="/">Home</Link>
//         </li>
//         <li>
//           <Link to="/about">About</Link>
//         </li>
//         <li>
//           <Link to="/contact">Contact</Link>
//         </li>
//       </ul>
//     </nav>
//   );
// }

// import React, { useState } from 'react';
import { HomeOutlined, LoginOutlined, InfoCircleOutlined, FileTextOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { isVisible } from "@testing-library/user-event/dist/utils";

const items = [
  {
    label: 'Home',
    key: 'home',
    path: '/',
    label: <Link to='/'>Home</Link>,
    icon: <HomeOutlined />,
  },
  {
    label: 'About',
    key: 'about',
    icon: <InfoCircleOutlined />,
    label: <Link to='/about'>About</Link>,
    disabled: false,
  },
    // children: [
    //   {
    //     type: 'group',
    //     label: 'Item 1',
    //     children: [
    //       {
    //         label: 'Option 1',
    //         key: 'setting:1',
    //       },
    //       {
    //         label: 'Option 2',
    //         key: 'setting:2',
    //       },
    //     ],
    //   },
    //   {
    //     type: 'group',
    //     label: 'Item 2',
    //     children: [
    //       {
    //         label: 'Option 3',
    //         key: 'setting:3',
    //       },
    //       {
    //         label: 'Option 4',
    //         key: 'setting:4',
    //       },
    //     ],
    //   },
    // ],

];

if (localStorage.getItem(GlobalObject.token) != null)
{
  items.push({
    label: 'Task root',
    key: 'taskroot',
    icon: <FileTextOutlined />,
    label: <Link to='/taskroot'>Task root</Link>,
  },)

  items.push({
    label: <Link to='/logout'>Logout</Link>,
    key: 'logout',
    icon: <LoginOutlined />
  },)
}
else 
{
  items.push({
    label: <Link to='/login'>Login</Link>,
    key: 'login',
    icon: <LoginOutlined />
  },
  )
}


const Navbar = () => {
  return <Menu mode="horizontal" items={items} />;
};

export default Navbar;