import React, { useState } from 'react';
import { Button, Input, Space } from 'antd';
import { Alert } from "antd"
import { Flex, Spin } from 'antd';
import Navbar from './Navbar'
import { BrowserRouter, Link, Navigate, Route } from 'react-router-dom';
import GlobalObject from './globalObject';
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [inputUsername, SetInputUsername] = useState('');
  const [inputPassword, SetInputPassword] = useState('');
  const [isOk, SetIsOk] = useState(false);
  const [isWorking, SetIsWorking] = useState(false);
  const [isError, SetIsError] = useState(false);
  const [errorMessage, SetErrorMessage] = useState('');
  const [jwtToken, SetJwtToken] = useState('');

  const userInput = React.createRef();
  const passwordInput = React.createRef();

  const handlePassword  = (event) => {
    SetInputPassword(event.target.value);
    SetIsError(false);
  };    

  const handleUsername = (event) => {
    SetInputUsername(event.target.value);
    SetIsError(false);
  };  

  
  const handleClick = async () => {
    try {
      SetIsWorking(true);
      var obj = {};
      obj['login'] = inputUsername;
      obj['password'] = inputPassword;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj)
      };

      const response = await fetch(process.env.REACT_APP_API_URL + '/v1/authenticate',  requestOptions );
      const data = await response.json();

      if (response.status != 200)
      {
        SetIsError(true);
        SetIsOk(false);

        SetErrorMessage(data.Message);
        SetIsWorking(false);
      }
      else {
        SetIsOk(true);
        SetIsError(false);
        localStorage.setItem(GlobalObject.token,  data.token);
        navigate('/taskroot', {state: GlobalObject})
        window.location.reload();
      }

    } catch (err) {
        console.log(err.message)
    }
    finally{
    }
  }

  function _handleKeyDown(e) {
    if (e.key === 'Enter' && inputUsername.length > 0 && inputPassword.length > 0) {
      handleClick();
    }
  }

  return (
    <div>
      <Navbar />

      <div><h1>H.O.T Login</h1></div>

      <div>
          <Space direction="vertical">
            <Input name="username" ref={userInput} placeholder="input username" onChange={handleUsername} onKeyDown={_handleKeyDown} />
            <Input.Password name="password" ref={passwordInput} placeholder="input password" onChange={handlePassword} onKeyDown={_handleKeyDown}/>

            {!isWorking ?
            <div tyle={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
              <Button type="primary" onClick={handleClick}>Login</Button>
            </div>
            : null}

            {isWorking ?
            <div  style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
              <Flex gap="middle">
                <Spin size="large"></Spin>
              </Flex> 
            </div>
            : null}
          </Space>

        </div>
        <div>
          <br></br>
        {isError && (
            <Alert
              message="Login error"
              description={errorMessage}
              type="error"
              />
            )}

        {isOk && (
            <Alert
              message="Login success"
              description="Comming in H.O.T for your task management"
              type="success"
              />
            )}
      </div>
    </div>
  );
};

export default Login;