import React, { useEffect, useState } from 'react'
import { Flex, Spin, Space, Table } from 'antd';
import { useNavigate } from "react-router-dom";
import Navbar from './Navbar'  
import GlobalObject from './globalObject';
import { Button, } from 'antd';

const TaskRoot = () => {
  const token = localStorage.getItem("JwtToken");
  const [rootTaskList, setRootTaskList] = useState([]);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' ,
               'Authorization': 'Bearer ' + localStorage.getItem(GlobalObject.token)},
  };

  useEffect(() => {
    setLoading(true)

    try {
      fetch(GlobalObject.url_api +  '/v1/task',  requestOptions )
        .then(response => response.json())
        .then(json => setRootTaskList(json))
        .finally(() => {
          setLoading(false)
        })
        .catch((error) => {
          navigate("/");
        })
      }
      catch{

      }
  }, [])

  function itemViewClick(task)
  {
    navigate('/taskedit?taskGuid=' + task.record.taskGuid, {state: GlobalObject})
  }

  function GoNew()
  {
      navigate('/newroottask');
  }

  const columns = [
    {
      title: 'Headline',
      dataIndex: 'headline',
      key: 'headline',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Action',
      key: 'action',
      render: (t, record) => (
        <Space size="middle">
          <a onClick={(task) =>  itemViewClick({record})}>View</a>
        </Space>
      ),
    },
  ];

  return (
    <div>
        <Navbar />

        <div><h1>Root tasks</h1></div>
        
        <div>
          <Space size="middle">
            <Button type="primary" onClick={GoNew}>Create new root task</Button>
          </Space>
        </div>

        {loading ? (
                <div  style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                <Flex gap="middle">
                  <Spin tip="" size="large">Loading tasks...</Spin>
                </Flex> 
              </div>
        ) : (
            
          <Table 
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => { 
                    //console.log(record)
                },
              }}}
              dataSource={rootTaskList} 
              columns={columns} 
              rowKey="taskGuid" />

        )}

    </div>
  );
}

export default TaskRoot;
