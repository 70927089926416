 //Home.js
 import React from 'react'
 import Navbar from './Navbar'
 import { Typography } from "antd";
 import { Divider } from 'antd';

 const { Title } = Typography;

 const Home = () => {

   return (
     <div>
        <Navbar />
        <Title level={1}>H.O.T</Title>
        <div>
          The  new way to [look, handle, hand out] tasks.
        </div>
        <div>
        <Divider
          style={{
            borderColor: '#7cb305',
          }}
        >
          <Title level={2}>Handle</Title>
          </Divider>
        </div>
        <div>
          <b>Verb (Action):</b>
          <div>
            To Manage or Deal With: As a verb, "handle" means to manage, control, or deal with a situation, task, or person. 
          <div>
          </div>
            For example, "He handled that task very well."
          </div>
        </div>
        <div>
          <Divider
            style={{
              borderColor: '#7cb305',
            }}
          >
            <Title level={2}>Opulent</Title>
          </Divider>
        </div>
        <div>

        "Opulent" is an adjective that describes something characterized by great wealth, luxury, and grandeur. It conveys a sense of richness and abundance, often with an emphasis on lavishness and extravagance. 
        <br></br>    
        <b>Grand and Magnificent:</b>
        <br></br>
          The word also conveys a sense of grandness or magnificence.
        </div>
        <div>
          An "opulent banquet" might be an elaborate feast with an array of sumptuous dishes, symbolizing not just abundance but also splendor and refinement.
        </div>
        <div>
        <Divider
            style={{
              borderColor: '#7cb305',
            }}
          >
          <Title level={2}>Task</Title>
        </Divider>
        </div>
        <div>


          <b>Verb (To Assign Work):</b>
          <div>
            To Assign a Task: When used as a verb, "to task" someone means to assign them a specific duty or piece of work, like "The manager tasked me with overseeing the project."
          </div>
        </div>
     </div>
   )
 }

 export default Home